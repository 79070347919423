// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import ProductPage from "../components/ProductPage";
import {resetProductScope, setProductScope} from "../actions/products";
import type {Dispatch} from "redux";
import {resetCategory} from "../actions/filter";

type ProductPageContainerProps = {
	history: any
}

function mapStateToProps(state: ApplicationState, ownProps: ProductPageContainerProps) {
	const product = state.content.activeProduct || null;
	const galleryImage = ((product || {}).product || {}).galleryImage;
	const otherImages = (((product || {}).attachments || {}).images || [])
		.filter(it => it.name !== (galleryImage || {}).name);
	const videos = (((product || {}).attachments || {}).videos || []);

	return {
		detailedProduct: product,
		hasMultipleGalleryImages: product && product.attachments && product.attachments.images && product.attachments.images.length > 1,
		filter: {
			fuzzy: state.filter.fuzzy,
			category: state.filter.filter.selectedCategory
		},
		disclaimer: state.content.disclaimer,
		cartDisabled: state.cart.cartDisabled,
		images: galleryImage ? [...[galleryImage], ...(otherImages || [])] : (otherImages || []),
		videos: videos
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: ProductPageContainerProps) {
	return {
		resetProductScope: () => {
			ownProps.history.push("/");
			dispatch(resetProductScope());
		},
		setProductScope: (productId: string) => {
			dispatch(setProductScope({id: productId}));
		},
		goBack: filter => {
			if (filter.fuzzy && filter.fuzzy.length >= 1) {
				return ownProps.history.push("/search/" + filter.fuzzy);
			} else if (filter.category && filter.category.key) {
				filter.category.count === 1 ? ownProps.history.push("/") : ownProps.history.push("/category/" + filter.category.key);
				dispatch(resetCategory());
			} else {
				ownProps.history.push("/");
			}
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
